<template>
  <div>
    <el-dialog
      title="企业认证信息"
      :visible.sync="show"
      width="900px"
      @close="$emit('update:dialogVisible', false)"
    >
      <el-form
        v-if="form"
        ref="form"
        :model="form"
        label-width="120px"
        size="small"
      >
        <el-form-item label="企业名称">
          <el-col :span="17" :offset="0">
            <el-input v-model="form.extend.name" />
          </el-col>
        </el-form-item>
        <el-form-item label="组织机构代码">
          <el-col :span="17" :offset="0">
            <el-input v-model="form.extend.organizingCode" />
          </el-col>
        </el-form-item>
        <el-form-item label="公司注册地址">
          <el-col :span="17" :offset="0">
            <el-input v-model="form.extend.registerAddress" />
          </el-col>
        </el-form-item>
        <el-form-item label="公司执照">
          <el-col :span="17" :offset="0">
            <img :src="baseurl + yu + form.extend.license" alt="" />
          </el-col>
        </el-form-item>
        <el-form-item label="授权人信息" style="">
          <p style="color: #8c939d; line-height: 6px">
            提交认证后，扬帆出海审核专员将联系授权人确认信息。授权人也作为企业在扬帆出海
          </p>
        </el-form-item>
        <el-form-item label="授权人姓名">
          <el-col :span="17" :offset="0">
            <el-input v-model="form.extend.authorizer" />
          </el-col>
        </el-form-item>
        <el-form-item label="授权人身份证号">
          <el-col :span="17" :offset="0">
            <el-input v-model="form.extend.authorizerCardNum" />
          </el-col>
        </el-form-item>
        <el-form-item label="授权人手机号">
          <el-col :span="17" :offset="0">
            <el-input v-model="form.extend.authorizerPhone" />
          </el-col>
        </el-form-item>
        <el-form-item label="授权人微信号">
          <el-col :span="17" :offset="0">
            <el-input v-model="form.extend.authorizerWechat" />
          </el-col>
        </el-form-item>
        <el-form-item label="授权人邮箱">
          <el-col :span="17" :offset="0">
            <el-input v-model="form.extend.authorizerEmail" />
          </el-col>
        </el-form-item>
        <el-form-item label="授权人身份证">
          <div class="box">
            <Img
              :banner.sync="form.extend.authorizerCardFront"
              :action="action"
              :tip="'正面'"
            />
          </div>
          <div class="box">
            <Img
              :banner.sync="form.extend.authorizerCardBack"
              :action="action"
              :tip="'反面'"
            />
          </div>
        </el-form-item>
        <el-form-item label="公司简介">
          <el-col :span="17" :offset="0">
            <el-input
              v-model="form.companyDesc"
              type="textarea"
              :rows="6"
              placeholder="请输入内容"
            />
          </el-col>
        </el-form-item>
        <el-form-item label="产品介绍">
          <el-col :span="17" :offset="0">
            <el-input
              v-model="form.productDesc"
              type="textarea"
              :rows="6"
              placeholder="请输入内容"
            />
          </el-col>
        </el-form-item>
        <el-form-item label="产品图片">
          <imgups
            :action="action"
            :list="this.form.productImages"
            @changes="c_img"
          />
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Img from './img.vue'
import imgups from '../../../components/uplist/index'
export default {
  components: { Img, imgups },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      show: this.dialogVisible,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      action: {
        action: 'enterprise'
      }
    }
  },
  watch: {
    dialogVisible() {
      this.show = this.dialogVisible
      console.log(this.form.productImages)
    }
  },
  methods: {
    c_img(val) {
      this.form.productImages = val
    },
    clear() {
      this.$emit('update:dialogVisible', false)
    },
    submit() {
      this.$http
        .post('/admin/Enterprise/check', {
          ...this.form,
          status: 4
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.$emit('update:dialogVisible', false)
            this.$emit('getlist')
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  display: inline-block;
  margin-right: 10px;
}
</style>
