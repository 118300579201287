<template>
  <div>
    <el-dialog
      title="审核不通过原因"
      :visible.sync="show"
      width="30%"
      style="text-align: center;"
      @close="$emit('update:dialogVisible', false)"
    >
      <template v-if="type == 1">
        <h1>
          <!-- <el-radio v-model="radio" label="1">企业基本信息不通过</el-radio>
          <el-radio v-model="radio" label="2">企业认证信息不通过</el-radio> -->
        </h1>
        <!-- <h1>企业基本信息不通过</h1> -->
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="0"
        >
          <el-form-item v-show="radio == 1" prop="remark">
            <el-input
              v-if="form"
              v-model="ruleForm.remark"
              type="textarea"
              :rows="7"
              placeholder=""
            />
          </el-form-item>
          <el-form-item v-show="radio == 2" prop="extendRemark">
            <el-input
              v-if="form"
              v-model="ruleForm.extendRemark"
              type="textarea"
              :rows="7"
              placeholder=""
            />
          </el-form-item>
        </el-form>
      </template>
      <template v-if="type == 2">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="0"
        >
          <el-form-item prop="remark">
            <el-input
              v-if="form"
              v-model="ruleForm.remark"
              type="textarea"
              :rows="7"
              placeholder=""
            />
          </el-form-item>
        </el-form>
      </template>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import rules from '@/utils/rules.js'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: {
        remark: '',
        extendRemark: ''
      }
    },
    type: {
      type: [String, Number],
      default: 1
    },
    custom: {
        type:[Boolean],
        default:false
    }
  },
  data() {
    return {
      show: this.dialogVisible,
      radio: '1',
      ruleForm: {
        extendRemark: '',
        remark: ''
      },
      rules: {
        remark: [rules.req('请填写企业基本信息不通过原因')],
        extendRemark: [rules.req('请填写企业认证信息不通过原因')]
      }
    }
  },
  watch: {
    dialogVisible() {
      this.show = this.dialogVisible
      if (this.show) {
        this.ruleForm = {
          extendRemark: '',
          remark: '',
          id: this.form.id
        }
      }
    }
  },
  methods: {
    clear() {
      this.$emit('update:dialogVisible', false)
    },
    async submit() {
      if (this.radio == 1) {
        await this.$refs['ruleForm'].validateField(['remark'], val => {
          if (val) {
            this.$message.error(val)
            return
          }
          if (this.custom) {
            this.$emit('submit',this.ruleForm)
            return
          }
          this.up()
        })
      }
      if (this.radio == 2) {
        await this.$refs['ruleForm'].validateField(['extendRemark'], val => {
          if (val) {
            this.$message.error(val)
            return
          }
          this.up()
        })
      }
    },
    async up() {
      if (this.type == 2) {
        const { data: res } = await this.$http.post('/admin/Enterprise/cert', {
          id: this.form.id,
          remark: this.ruleForm.remark,
          status: 2
        })
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          this.$emit('update:dialogVisible', false)
          this.$emit('getlist')
        }
        return
      }
      this.$http
        .post('/admin/Enterprise/check', {
          id: this.ruleForm.id,
          extendRemark: this.radio == 1 ? '' : this.ruleForm.extendRemark,
          remark: this.radio == 1 ? this.ruleForm.remark : '',
          status: 4
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.$emit('update:dialogVisible', false)
            this.$emit('getlist')
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
h1 {
  font-size: 16px;
  // font-weight: bold;
  margin: 15px;
}
.el-dialog__header {
  text-align: center;
}
</style>
