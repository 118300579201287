<template>
  <div>
    <el-card>
      <nav class="out">
        <nav class="input">
          <el-input
            v-model="form_2.name"
            size="medium"
            placeholder="公司名称"
          />
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="form_2.isHide" placeholder="状态" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option label="上架" :value="1" />
            <el-option label="下架" :value="0" />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="form_2.type" placeholder="类型" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option
              v-for="(item, index) in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="form_2.business" placeholder="行业" size="medium">
            <el-option label="全部" :value="-1" />
            <!-- <el-option
              v-for="(item, index) in businessList"
              :key="item.key"
              :label="item"
              :value="index"
            /> -->
            <el-option
              v-for="(item, index) in businessList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select
            v-model="form_2.subBusiness"
            placeholder="二级行业"
            size="medium"
          >
            <el-option label="全部" :value="-1" />
            <el-option
              v-for="(item, index) in subBusinessList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select
            v-model="form_2.serverArea"
            placeholder="业务区域"
            size="medium"
          >
            <el-option label="全部" :value="-1" />

            <el-option
              v-for="(item, index) in this.$store.state.enterprise_config &&
                this.$store.state.enterprise_config.enterpriseServerArea"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select
            v-model="form_2.people"
            placeholder="公司规模"
            size="medium"
          >
            <el-option label="全部" :value="-1" />
            <el-option
              v-for="(item, index) in this.$store.state.enterprise_config &&
                this.$store.state.enterprise_config.enterprisePeople"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select
            v-model="form_2.financing"
            placeholder="融资情况"
            size="medium"
          >
            <el-option label="全部" :value="-1" />
            <el-option
              v-for="(item, index) in this.$store.state.enterprise_config &&
                this.$store.state.enterprise_config.enterpriseFinancing"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </nav>
        <nav class="input" style="width: 250px;">
          <el-date-picker
            v-model="form_2.createDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 100%"
            size="medium"
          />
        </nav>
        <!-- <nav class="input" style="width: 150px">
          <el-select v-model="form_2.claim" placeholder="来源" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option label="认领" :value="1" />
          </el-select>
        </nav> -->
        <nav class="input" style="width: 150px">
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="subimt">{{
            '搜索'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="Logo" label="Logo">
          <template slot-scope="{ row }">
            <img :src="baseurl + yu + row.logo" alt="" style="width: 50px;" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="企业简称">
          <template slot-scope="{ row }">
            <!-- <a :href="row.website" target="_blank">{{ row.name }}</a> -->
            {{ row.name }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="企业全称">
          <template slot-scope="{ row }">
            <span>{{ row.fullName }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="desc" label="企业等级">
          <template slot-scope="{ row }">
            <p>{{ row.gradeName }}</p>
          </template>
        </el-table-column> -->
        <el-table-column prop="city" label="地址">
          <template slot-scope="{ row }">
            {{ row.provinceName + '-' + row.cityName + '-' + row.address }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型行业">
          <template slot-scope="{ row }">
            {{ row.typeName + '/' + row.businessName.toString() }}
          </template>
        </el-table-column>
        <el-table-column prop="areaName" label="业务区域">
          <template slot-scope="{ row }">
            {{ row.areaName.toString() }}
          </template>
        </el-table-column>
        <el-table-column prop="peopleName" label="公司规模" />

        <el-table-column prop="financingName" label="融资情况" />
        <el-table-column prop="status" label="基本信息状态">
          <template slot-scope="{ row }">
            <el-tag
              v-show="show_status(row.status)"
              type="warning"
              size="small"
              class="box"
              >{{ show_status(row.status) }}</el-tag
            >
            <div v-show="row.status == 4 || row.claim == 1">
              <span v-show="row.remark"
                >企业基本信息不通过：{{ row.remark }}</span
              >
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="status" label="认证信息状态">
          <template slot-scope="{ row }" v-if="row.extend">
            <el-tag
              v-show="show_status(row.extend.status)"
              type="warning"
              size="small"
              class="box"
              >{{ show_status(row.extend.status) }}</el-tag
            >
            <div v-show="row.extend.status == 4 || row.claim == 1">
              <span v-show="row.extend.remark">
                企业认证信息不通过：{{ row.extend.remark }}
              </span>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="certCreateTime" label="提交时间" />
        <!-- <el-table-column prop="claim" label="认领" width="120">
          <template slot-scope="{ row }">
            <img
              v-show="$toData(row.user)"
              :src="
                row.user.avatar || baseurl + yu + '/static/pc/img/avatar.png'
              "
              alt=""
              style="width: 80px"
            />
            <p v-show="row.user.realName || row.user.nickname">
              {{ row.user.realName || row.user.nickname }}
            </p>
            <el-tag type="warning" size="small">
              {{ row.claim ? '认领' : '未认领' }}
            </el-tag>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="remark" label="备注">
          <template slot-scope="{ row }">

          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="150">
          <template slot-scope="ew">
            <el-button
              v-show="$store.state.btn_if.indexOf('enterprise_cert_edit') != -1"
              type="text"
              icon="el-icon-edit"
              size="mini"
              style="color: #f9856a;"
              @click="edit_u(ew.row.id)"
              >编辑</el-button
            >
            <el-button
              v-show="
                $store.state.btn_if.indexOf('enterprise_cert_check') != -1
              "
              type="text"
              icon="el-icon-edit"
              size="mini"
              class="skyblue"
              @click="edit(ew.row)"
              >审核通过</el-button
            >
            <el-button
              v-show="
                $store.state.btn_if.indexOf('enterprise_cert_check') != -1
              "
              type="text"
              icon="el-icon-edit"
              size="mini"
              class="red"
              @click="edit_f(ew.row)"
              >审核不通过</el-button
            >

            <el-button
              v-show="
                $store.state.btn_if.indexOf('enterprise_cert_change_manager') !=
                  -1
              "
              type="text"
              icon="el-icon-s-custom"
              size="mini"
              class="pink"
              @click="edit_man(ew.row)"
              >更改管理员</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="currentPage"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <pass
      ref="pass"
      :dialog-visible.sync="p_a"
      :row="p_a_f"
      @getlist="getlist"
    />
    <failing :dialog-visible.sync="dioA" :form="p_a_f" @getlist="getlist" />
    <Info :dialog-visible.sync="showinfo" :form="p_a_f" />
    <chagne ref="chageref" @getlist="getlist" />
  </div>
</template>

<script>
let that
import rules from '../../utils/rules'
import show from '../../components/countDowns/show.vue'
// import mixins from '../../mixin/getdiction'
import remark from '../../components/remark/orderNum.vue'
import pass from './enterprise_cert_list/pass.vue'
import failing from './enterprise_cert_list/failing.vue'
import Info from './enterprise_cert_list/info.vue'
export default {
  components: {
    Show: show,
    Remark: remark,
    pass,
    failing,
    Info,
    chagne: () => import('./enterprise_change.vue')
  },
  data() {
    return {
      dioA: false,
      p_a: false,
      p_a_f: null,
      showinfo: false,
      date: [],
      title: '',
      year: '',
      publishStatus: '',
      reportArea: '',
      reportBusiness: '',
      order: '',
      confing: '',
      btn: true,
      action: {
        action: 'enterprise'
      },
      dioaddkuai: false,
      currentPage: 1,
      pagesize: 10,
      list: [],
      pai: [],
      paiform: {},
      value: '',
      dialogVisible: false,
      dialogVisiblexui: false,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      total: 0,
      proof: false,
      form: {
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      form_1: {
        id: '',
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      form_2: {
        page: 1,
        pageSize: 10,
        name: '',
        isHide: '',
        type: '',
        business: '',
        subBusiness: '',
        serverArea: '',
        createDate: [],
        people: '',
        financing: '',
        claim: ''
        // order: ''
      },
      form_3: {
        phone: this.$cookies.get('phone')
      },
      s_form: {
        page: 1,
        pageSize: 10,
        name: '',
        isHide: '',
        type: '',
        business: '',
        subBusiness: '',
        serverArea: '',
        createDate: [],
        people: '',
        financing: '',
        claim: ''
      },
      imageUrl: '',
      imageUrl_1: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      form_show: {},
      area: [],
      businessList: [],
      typeList: [],
      subBusiness: []
    }
  },
  watch: {
    'form_2.type'(newValue, oldValue) {
      this.form_2.business = ''
      this.form_2.subBusiness = ''
      this.getType('1')
    },
    'form_2.business'(newValue, oldValue) {
      this.form_2.subBusiness = ''
      this.getType('2')
    }
  },
  computed: {
    show_status() {
      return val => {
        var key
        switch (val) {
          case 1:
            key = '草稿'
            break
          case 2:
            key = '提交待审核'
            break
          case 3:
            key = '审核通过'
            break
          case 4:
            key = '审核不通过'
          default:
            break
        }
        return key
      }
    }
  },
  // mixins: [mixins],
  beforeCreate() {
    that = this
  },
  updated() {
    //   console.log(this.form);
  },

  created() {
    // this.getconfig()
    // this.getconfig('user')
    this.getlist()
    sessionStorage.setItem('action', 'enterprise')
  },
  mounted() {
    // console.log(rules.req('撒打算'))
    // console.log(this.confing_d)
    this.getType()
  },
  methods: {
    edit_man(val) {
      this.$refs.chageref.dialogVisible = true
      console.log(val.id)
      this.$refs.chageref.form.id = val.id
    },
    async getType(val) {
      if (val == 1) {
        var { data: res } = await this.$http.get(
          `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.form_2.type}&businessId=`
        )
        this.businessList = res.data.list
        return
      }
      if (val == 2) {
        var { data: res } = await this.$http.get(
          `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.form_2.type}&businessId=${this.form_2.business}`
        )
        this.subBusinessList = res.data.list
        return
      }
      var { data: res } = await this.$http.get(
        '/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=0&businessId='
      )
      this.typeList = res.data.list
    },
    reset() {
      this.form_2 = {
        page: 1,
        pageSize: 10,
        name: '',
        isHide: '',
        type: '',
        business: '',
        serverArea: '',
        createDate: [],
        people: '',
        financing: '',
        claim: ''
        // order: ''
      }
      this.s_form = { ...this.form_2 }
      this.getlist()
    },
    subimt() {
      this.currentPage = 1
      this.form_2.page = 1
      this.s_form = { ...this.form_2 }
      this.getlist()
    },
    check() {
      console.log(1)
    },
    async show_qy(row) {

      
      //   this.$router.push({
      //     name: 'enterprise_show_info',
      //     params: {
      //       id: row.id
      //     }
      //   })
      // let { data: res } = await this.$http.get(
      //   '/admin/Enterprise/getById?id=' + row.id
      // )
      // // console.log(res)
      // if (res.errorCode == 200) {
      //   this.showinfo = true
      //   this.p_a_f = {
      //     ...res.data,
      //     productImages: res.data.productImages.split(',')
      //   }
      //   this.p_a_f.productImages = this.p_a_f.productImages.map(item => {
      //     return {
      //       name: item,
      //       url: this.baseurl + this.yu + item
      //     }
      //   })
      // } else {
      //   this.$message.error(res.message)
      // }
    },
    information(row) {
      this.showinfo = true
      // this.form_show = row
      this.$http
        .get('/admin/UserSpeaker/getById', { params: { id: row.id } })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.form_show = res.data
          }
        })
    },
    datum(row) {
      location.href = this.baseurl + this.yu + row.document
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'report_order_num'
        } else if (c.prop == 'views') {
          s = 'report_views'
        } else {
          s = 'report_' + c.prop
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },

    addkuai() {
      this.addkuai = false
    },
    getea() {
      this.$http.get('/admin/ReportCategory/getList').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.area = res.data
        }
      })
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },
    handleCurrentChange(e) {
      this.s_form.page = e
      this.form_2.page = e
      this.getlist(this.s_form)
    },
    getlist(val) {
      var form = val || this.form_2
      this.$http
        .get('/admin/Enterprise/getCertList', {
          params: {
            ...form,
            createDate: form.createDate.join('~')
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(res)
            this.list = res.data.list
            // console.log(this.list)
            this.total = res.data.totalCount
          }
        })
    },
    checkf(val) {
      this.form_3 = val
      // console.log(this.form_3)
    },
    add() {},
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        this.form.banner = res.data.url
      }
      console.log(file)
      //   console.log(imgurlbase)
    },

    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg'
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!')
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
    handleAvatarSuccess_1(res, file) {
      if (res.errorCode == 200) {
        this.form_1.banner = res.data.url
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeAvatarUpload_1(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },

    addedit() {
      if (this.btn) {
        // this.form.picture = this.form.picture.slice(this.baseurl.length)

        this.$http
          .post('/admin/News/edit', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.dialogVisiblexui = false
              this.$message.success(res.message)
              this.getlist()
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    edit(id) {
      // sessionStorage.setItem('flag', false)
      //   this.p_a = true
      this.p_a_f = id
      this.$refs.pass.submit()
    },
    edit_u(id) {
      this.$router.push({
        name: 'enterprise_add',
        query: {
          id: id
        }
      })
    },
    edit_f(row) {
      this.dioA = true
      this.p_a_f = JSON.parse(JSON.stringify(row))
    },

    setTop(row, val) {
      // console.log(1)
      // console.log(row, id)
      this.$http
        .post('/admin/Enterprise/top', {
          id: row.id,
          top: val
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              customClass: 'zZindex'
            })
          }
          // this.btn1 = true
        })
    },
    show(id, el) {
      this.$http
        .post('/admin/Enterprise/hide', { id: id, isHide: el })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          }
        })
    },
    chagne(row) {
      this.$http
        .post('/admin/UserSpeaker/editService', {
          id: row.id,
          waId: row.waId.join(',')
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.getlist()
          } else {
            this.getlist()
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;
  .input {
    width: 37%;
    display: inline-block;
    margin-right: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
.out .input {
  width: 18%;
}
.el-card {
  position: relative;
}
.out .right {
  float: right;
}
.box {
  display: inline-block;
  margin: 5px;
}
</style>
